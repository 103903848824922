<template>
    <div
            class="plex-compressed-sidebar"
            :class="{'is-inactive': !activeMenu}"
    >
        <div class="compressed-header">
          <figure class="image is-48x48">
            <img src="@/assets/logo.png" alt="Logo" class="logo"
                 v-if="(this.$auth.user().data_branch_office || false) && (!this.$auth.user().data_branch_office.group || this.$auth.user().data_branch_office.group === 'LAPI')">
            <img src="@/assets/tadeo/icon.png" alt="Logo" class="logo"
                 v-else-if="(this.$auth.user().data_branch_office || false) && this.$auth.user().data_branch_office.group && this.$auth.user().data_branch_office.group === 'TADEO'">
          </figure>
        </div>
        <div class="compressed-item is-bordered">
            <button class="menu-icon v-5" @click="$store.dispatch('toggleCompressMenu')">
                <span></span>
            </button>
        </div>
        <div class="compressed-menu">
            <router-link
                    class="compressed-item tooltip is-tooltip-right"
                    v-for="item in links"
                    :to="item.route"
                    :key="item.name"
                    :exact="item.exact"
                    v-if="$auth.check(item.auth) && ($alfalab.allowOnlyLapi($auth.user(), item)) && ($alfalab.enableByKey($auth.user(), item))  || $alfalab.isAdmin($auth.user())"
                    :data-tooltip="$t(`account.menu.links.${item.name}`)"
            >
                <b-icon :icon="item.icon"></b-icon>
            </router-link>
            <div class="compressed-naver"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MainSideBarCompressed",
        computed: {
            activeMenu() {
                return this.$store.state.utils.compressMenu;
            },
            links() {
              if(this.$auth.user().active_branch_office.toString() === '15'){
                return this.$store.state.menu.linksGuardiaNacional;
              }
              return this.$store.state.menu.links
            }
        }
    }
</script>

<style scoped>

</style>
